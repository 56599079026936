<template>
  <div ref="ModalBoxRef">
    <a-modal
      v-model:visible="visible"
      :onCancel="handleCancel"
      @ok="handleOk"
      :getContainer="() => ModalBoxRef"
      centered
    >
      <p class="title">设置角色</p>
      <a-divider />
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">保存</a-button>
      </template>
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="用户角色">
          <a-select v-model:value="roleId" placeholder="请选择用户角色" >
            <a-select-option v-for="item in listRole" :key="item" :value="item.id">
              {{ item.roleName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, toRefs, reactive } from 'vue';
import { message } from 'ant-design-vue';
import api from '@/services';
export default defineComponent({
  setup() {
    const loading = ref(false);
    const visible = ref(false);

    const ModalBoxRef = ref();

    const labelCol = reactive({ span: 4, offset: 3 });
    const wrapperCol = reactive({ span: 14 });
    const formState = reactive({
      roleId: '',
      userIdList: []
    });

    const listRole = ref([])

    const showModal = userIdList => {
      visible.value = true;
      formState.userIdList = userIdList.value;
      getRoleList()
    };

    const handleOk = async () => {
      loading.value = true;
      let params = {
        userIdList: formState.userIdList,
        roleId: formState.roleId
      };
      const { success } = await api.batchBindRole(params);
      if (success) {
        message.success('批量设置成功！');
      }
      loading.value = false;
      handleCancel();
    };

    const getRoleList = async () => {
      const { data } = await api.getSetRoleList();
      listRole.value = data
      console.log(listRole.value)
    };

    const handleCancel = () => {
      visible.value = false;
      clearForm();
    };

    const clearForm = () => {
      Object.keys(formState).map(key => {
        formState[key] = '';
      });
    };

    return {
      labelCol,
      wrapperCol,
      loading,
      visible,
      showModal,
      handleOk,
      handleCancel,
      ModalBoxRef,
      clearForm,
      listRole,
      ...toRefs(formState)
    };
  }
});
</script>

<style lang="less" scoped>
::v-deep(.ant-modal-body) {
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
}
</style>
