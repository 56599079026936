<template>
  <div class="wrap">
    <p class="title">本站用户</p>
    <!-- 搜索 -->
    <a-form layout="inline" :model="formState">
      <a-form-item label="登陆名称">
        <a-input v-model:value.trim="formState.username" placeholder="请输入登陆名称" size="small"></a-input>
      </a-form-item>
      <a-form-item label="用户昵称">
        <a-input v-model:value.trim="formState.nickName" placeholder="请输入用户昵称" size="small"></a-input>
      </a-form-item>
      <a-form-item label="组织" >
        <a-tree-select
          size="small"
          style="width: 163px"
          v-model:value="formState.userIds"
          :tree-data="treeData"
          tree-checkable
          :maxTagCount="1"
          check
          :replaceFields="replaceFields"
          allow-clear
          placeholder="请输入组织名称"
        />
      </a-form-item>
      <a-form-item label="角色">
        <a-input v-model:value.trim="formState.role" placeholder="请输入角色名称" size="small"></a-input>
      </a-form-item>
      <a-form-item class="btn-group">
        <a-button type="primary" size="small" @click="search">查询</a-button>
        <a-button size="small" @click="reset">重置</a-button>
      </a-form-item>
    </a-form>
    <!-- 操作 -->
    <div class="action">
      <a-button type="primary" size="small" @click="showSetRoleModal">批量设置角色</a-button>
      <a-button size="small" @click="showAddEditModal">新增用户</a-button>
    </div>
    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="pagination"
      :row-selection="{ selectedRowKeys: selectedRowKeys.value, onChange: onSelectChange }"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #avatar="row">
        <img class="avatar" :src="row.record.avatar" alt="头像" />
      </template>
      <template #isOnLine="row">
        <span class="status-tag">{{ row.record.isOnLine === 1 ? '在线' : '离线' }}</span>
      </template>
      <template #action="row">
        <div class="action">
          <span class="freeze" @click="freeze(row)">{{ row.record.status ? '解冻' : '冻结' }}</span>
          <span class="edit" @click="showAddEditModal(row)">编辑</span>
          <span class="del" @click="del(row)">删除</span>
        </div>
      </template>
    </a-table>
  </div>
  <!-- 新增 / 编辑 用户弹窗 -->
  <add-edit-user-modal ref="AddEditUserRef" @addOrUpdateSuccess="search"></add-edit-user-modal>
  <!-- 设置角色弹窗 -->
  <set-role-modal ref="SetRoleModalRef"></set-role-modal>
</template>

<script>
import { defineComponent, createVNode, ref, toRefs, reactive, onMounted } from 'vue';
import api from '@/services';
import { Modal, message } from 'ant-design-vue';
import AddEditUserModal from './AddEditUserModal';
import SetRoleModal from './SetRoleModal';
// 替换数组建原有字段
const replaceFields = {
  children: 'children',
  title: 'title',
  value: 'key',
  key: 'key'
};
const columns = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    align: 'center',
    ellipsis: true
  },
  {
    title: '登陆名称',
    key: 'username',
    dataIndex: 'username',
    align: 'center',
    ellipsis: true
  },
  {
    title: '用户昵称',
    key: 'nickName',
    dataIndex: 'nickName',
    align: 'center'
  },
  {
    title: '组织',
    key: 'departmentName',
    dataIndex: 'departmentName',
    align: 'center',
    ellipsis: true
  },
  {
    title: '角色',
    key: 'role',
    dataIndex: 'role',
    align: 'center',
    ellipsis: true
  },
  {
    title: '手机号',
    key: 'telephone',
    dataIndex: 'telephone',
    align: 'center'
  },
  {
    title: '头像',
    key: 'avatar',
    dataIndex: 'avatar',
    align: 'center',
    ellipsis: true,
    slots: {
      customRender: 'avatar'
    }
  },
  // {
  //   title: '最后登陆时间',
  //   key: 'lastLoginTime',
  //   dataIndex: 'lastLoginTime',
  //   align: 'center'
  //   // ellipsis: true
  // },
  // {
  //   title: '最后登陆IP',
  //   key: 'lastLoginIP',
  //   dataIndex: 'lastLoginIP',
  //   align: 'center',
  //   ellipsis: true
  // },
  {
    title: '状态',
    key: 'isOnLine',
    dataIndex: 'isOnLine',
    align: 'center',
    slots: {
      customRender: 'isOnLine'
    }
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 200,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  components: {
    AddEditUserModal,
    SetRoleModal
  },
  setup() {
    onMounted(() => {
      getUserList();
      publisherFiltering();
    });
    let pagination = ref({
      pageSize: 5,
      current: 1,
      total: 0,
      showSizeChanger: true,
      pageSizeOptions: ["5", "20", "50", "100"],//每页中显示的数据
    })

    const AddEditUserRef = ref();
    const SetRoleModalRef = ref();

    const addEditVisible = ref(false); // 控制 新增 & 编辑 用户弹窗显隐
    const batchSetVisible = ref(false); // 控制批量设置用户弹窗显隐
    const isEdit = ref(false);

    const selectedRowKeys = reactive([]);

    // table 相关变量
    const tableState = reactive({
      // pageSize: 5,
      // curPage: 1,
      // total: 0,
      dataSource: [],
      columns
    });

    // 条件筛选表单
    const formState = reactive({
      username: '',
      nickName: '',
      userIds: [],
      role: ''
    });

    // 获取用户列表 table data
    const getUserList = async () => {
      let paramsFormState = { ...formState }
      paramsFormState.userIds = formState.userIds.map(Number).filter(item => item);

      const params = {
        ...paramsFormState,
        curPage: pagination.value.current,
        pageSize: pagination.value.pageSize
      };
      const { data, success } = await api.getUserList(params);
      if (success) {
        tableState.dataSource = data.records;
        pagination.value.total = data.total;
      }
    };

    const onSelectChange = keys => {
      selectedRowKeys.value = keys;
    };

    const handlePageChange = page => {
      pagination.value.current = page.current;
      pagination.value.pageSize = page.pageSize
      getUserList();
    };

    // 显示新增/编辑用户弹窗
    const showAddEditModal = e => {
      let rowData;
      if (e.record) {
        isEdit.value = true;
        rowData = e.record;
      } else {
        isEdit.value = false;
      }
      console.log(rowData)
      AddEditUserRef.value.showModal(isEdit.value, rowData);
    };

    // 显示设置角色弹窗
    const showSetRoleModal = () => {
      console.log('ID list', selectedRowKeys);
      SetRoleModalRef.value.showModal(selectedRowKeys);
    };

    // 冻结
    const freeze = async row => {
      console.log('freeze', row.record.status);
      // message.info(`你冻死了尊贵的 ${row.record.username} ！`);
      let params = {
        id: row.record.id,
        status: row.record.status ? 0 : 1
      };
      const { success } = await api.toggleFreezeUser(params);
      if (success) {
        message.success('操作成功！');
        getUserList();
      }
    };

    // 删除
    const del = row => {
      // message.error(`你怎么敢删除尊贵的 ${row.record.username} 啊？`);
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `确认要删除用户 ${row.record.username} 吗？`
        ),

        async onOk() {
          let { success } = await api.delUserById(row.record.id);
          if (success) {
            message.success('删除成功！');
            getUserList();
          }
        }
      });
    };

    const search = () => {
      console.log(pagination)
      pagination.value.current = 1;
      // tableState.curPage = 1;
      getUserList();
    };

    const reset = () => {
      pagination.value.current = 1;
      Object.keys(formState).map((key) => {
        if(key == 'userIds') {
          formState[key] = []
        } else {
          formState[key] = ''
        }
      });
      getUserList();
    };

    const treeData = ref([]);
    const publisherFiltering = async () => {
      const { data } = await api.publisherFiltering();
      treeData.value = data;
    };

    return {
      selectedRowKeys,
      AddEditUserRef,
      SetRoleModalRef,
      addEditVisible,
      batchSetVisible,
      formState,
      getUserList,
      handlePageChange,
      showAddEditModal,
      showSetRoleModal,
      onSelectChange,
      search,
      reset,
      freeze,
      del,
      ...toRefs(tableState),
      pagination,
      treeData,
      replaceFields
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .action {
    display: flex;
    justify-content: flex-start;
    .ant-btn {
      margin-right: 20px;
    }
  }
  ::v-deep(.ant-form) {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
  ::v-deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .status-tag,
      .action > span {
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }
      .status-tag {
        background-color: #4acc90;
        color: #fff;
      }

      .action {
        span {
          cursor: pointer;
          color: #fff;
        }
        .freeze {
          background-color: #faa755;
          margin-right: 14px;
        }
        .edit {
          background-color: #007fff;
          margin-right: 14px;
        }
        .del {
          background-color: #d71345;
        }
      }
    }
  }
}

</style>
